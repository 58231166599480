import { IBody } from '@pra-vendas-themes/interfaces/IBody';

import { WithChildren } from 'types/WithChildren';

import { Wrapper } from './styles';

const Body: WithChildren<IBody> = ({ hasSpacingY, children }) => {
  return (
    <Wrapper className="Body" hasSpacingY={hasSpacingY}>
      {children}
    </Wrapper>
  );
};

export default Body;
