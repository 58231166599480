import styled from 'styled-components';

type TWrapper = {
  hasSpacingY?: boolean;
};

export const Wrapper = styled.div<TWrapper>`
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  max-width: ${(p) => p.theme.maxWidth};
  padding: ${(p) => (p.hasSpacingY ? p.theme.spacing.xLarge : 0)}
    ${(p) => p.theme.spacing.xLarge};

  @media (max-width: 768px) {
    padding: ${(p) => (p.hasSpacingY ? p.theme.spacing.medium : 0)}
      ${(p) => p.theme.spacing.medium};
  }
`;
